import { Box, Typography, Container, Link } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary">
      {'Copyright © '}
      <Link color="inherit" href="https://celiart.mx/">
        Celiart MX
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function Footer() {
  return (
    <>
      <Box
        sx={{
          display: 'flex'
          // minHeight: '100vh'
        }}
      >
        <Box
          component="footer"
          sx={{
            py: 3,
            px: 2,
            mt: 'auto',
          }}
        >
          <Container maxWidth="sm">
            <Link color="inherit" target="_blank" href="https://www.instagram.com/celiart.mx/">
              <InstagramIcon />
            </Link>
            <Link color="inherit" target="_blank" href="https://www.facebook.com/CeliArt.mx/">
              <FacebookIcon />
            </Link>
            <Copyright />
          </Container>
        </Box>
      </Box>
    </>
  );
}

export default Footer;