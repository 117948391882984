import { Grid } from '@mui/material';
import celia from '../images/celia.jpeg';

function AboutMe() {

  return(
    <>
      <Grid container spacing={2} className="aboutMe">
        <Grid item md={4}>
          <img className="aboutMeImg" src={celia} alt="Celia de Gonzalez" />
        </Grid>
        <Grid item md={8} className="aboutMeText">
          <p>
            &emsp; Autodidacta jugando experimentando y aprendiendo en cada proceso creativo buscando expresar de manera
            original lo que lleva dentro.
            Artista visual inspirada por los elementos de la naturaleza como tierra, aire, fuego, agua, madera y metal, y en
            su busqueda de crecer como persona y artista, tratando de expresar la conexión de los seres humanos con la
            naturaleza, la espiritualidad, y el deseo de vivir la vida de la mejor manera.
            Con obra que abarca pintura, escultura, dibujo e instalación. Utilizando técnica mixta sobre material natural
            como es la madera, textiles, metales,etc. Y rescatando naturaleza muerta para transformarla en arte,con
            formas abstractas llenas de color es la manera favorita de trasmitir su sentir.
          </p>
          <p>
            &emsp; Nacida en Monterrey, N.L. con estudios en Lic. en Mercadotecnia y Diseño de Modas en CEDIM, además de
            Diplomados Cursos y Talleres en Museo de Arte Contemporáneo MARCO y la Escuela Adolfo Prieto de
            CONARTE, entre otros.
          </p>
          <p>
            &emsp; Cuenta con tres exposiciones en individual en Museo Metropolitano de Monterrey Centro Cultural Bam y
            Museo Regional de Nuevo León El Obispado, participa en V bienalinternacional de Argentina, y mas de 20
            exposiciones colectivas nacionales e internacionales en recintos como; Centro Cultural Borges Buenos Aires
            Argentina, Torrego Art Gallery Sau Paulo Brasil, El Museo de Arte Contemporáneo MARCO, Museo del
            Desierto, Saltillo Coahuila, Museum of Art &amp; Science en McAllen Texas, Museo de Guadalupe, Casa de La
            Curtura San Pedro y Museo  San Bernabe de las casas Mina, N.L. entre otros. Cuentan con obra de la
            mencionada artista como parte de su patrimonio El Museo de Guadalupe, N.L. y El Museo San Bernabé de las
            Casas, Mina N.L.
          </p>
        </Grid>
      </Grid>     
    </>
  );
}

export default AboutMe;