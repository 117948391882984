import { Toolbar, Box, Link } from '@mui/material';
import { NavLink } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import celiartLogo from '../images/celiart.jpg';

function Header() {
  const sections = [{
    title: "Obras",
    url: "/"
  }, {
    title: "Acerca de mí",
    url: "/acercaDeMi"
  }, {
    title: "Contacto",
    url: "/contacto"
  }];

  return (
    <div>
      <Toolbar sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Box sx={{ flex: 1 }}>
          <img src={celiartLogo} alt="Celiart MX" />
        </Box>
        <Link color="inherit" target="_blank" href="https://www.instagram.com/celiart.mx/">
          <InstagramIcon />
        </Link>
        <Link color="inherit" target="_blank" href="https://www.facebook.com/CeliArt.mx/">
          <FacebookIcon />
        </Link>
      </Toolbar>
      <Toolbar
        component="nav"
        variant="dense"
        sx={{ justifyContent: 'center', overflowX: 'auto' }}
      >
        {sections.map((section) => (
            <Link
              color="inherit"
              noWrap
              key={section.title}
              sx={{ p: 1, flexShrink: 0 }}
              component={NavLink}
              to={section.url}
            >
              {section.title}
            </Link>
        ))}
      </Toolbar>
    </div>
  );
}

export default Header;