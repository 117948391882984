const artwork = [
  {
    id: 'nat01',
    title: 'Vehemencia',
    description: 'Inspirada en el fuego 🔥.',
    technique: 'Técnica mixta sobre tronco de madera sobre lino',
    dimensions: '1.90 x 1.30',
    image: '/images/vehemencia.jpg',
    tag: 'Arte con naturaleza rescatada' 
  }, {
    id: 'nat02',
    title: 'alas',
    description: '',
    technique: '',
    image: '/images/alas.jpg',
    tag: 'Arte con naturaleza rescatada' 
  }, {
    id: 'nat03',
    title: 'braquet',
    description: '',
    technique: '',
    image: '/images/braquet.jpg',
    tag: 'Arte con naturaleza rescatada' 
  }, {
    id: 'nat04',
    title: 'Veneración Intrínseca',
    description: 'Un trozo de tronco natural de madera me inspiró a crear esta obra, por su forma real de corazón humano.',
    technique: 'Técnica mixta en tronco natural de madera, sobre bastidor de madera y lino, incrustación de metal.',
    dimensions: '60cms x 64cms',
    image: '/images/VeneracionIntrinseca.jpg',
    tag: 'Arte con naturaleza rescatada',
    yearCreated: 2021
  }, {
    id: 'nat05',
    title: 'Almas Gemelas',
    description: 'Inspirada en la conexión que se va dando a lo largo de nuestras vidas con aquellas personas que se cruzan en nuestros caminos...',
    technique: '',
    image: '/images/AlmasGemelas.jpg',
    tag: 'Arte con naturaleza rescatada' 
  }, {
    id: 'nat06',
    title: 'Madera Vertical',
    description: '',
    technique: '',
    image: '/images/MaderaVertical.jpg',
    tag: 'Arte con naturaleza rescatada' 
  }, {
    id: 'vir01',
    title: 'Reyna de Este Monte',
    description: '',
    tecnique: '',
    image: '/images/ReynaDeEsteMonte.jpg',
    tag: 'Oda Guadalupana'
  }, {
    id: 'vir02',
    title: 'Fé sin Límites',
    description: '',
    tecnique: '',
    image: '/images/FeSinLimites.jpg',
    tag: 'Oda Guadalupana'
  }, {
    id: 'vir03',
    title: 'Universo Mexica',
    description: '',
    tecnique: '',
    image: '/images/UniversoMexica.jpg',
    tag: 'Oda Guadalupana'
  }, {
    id: 'vir04',
    title: 'Visita Milagrosa',
    description: '',
    tecnique: '',
    image: '/images/VisitaMilagrosa.jpg',
    tag: 'Oda Guadalupana'
  }, {
    id: 'vir05',
    title: 'Dorada',
    description: '',
    tecnique: '',
    image: '/images/dorada.jpg',
    tag: 'Oda Guadalupana'
  }, {
    id: 'per01',
    title: 'Personalizado Primero',
    image: '/images/perPrimero.jpg',
    tag: 'Arte personalizado a tu espacio'
  }, {
    id: 'per02',
    title: 'Personalizado Segundo',
    image: '/images/perSegundo.jpg',
    tag: 'Arte personalizado a tu espacio'
  }, {
    id: 'per03',
    title: 'Personalizado Tercero',
    image: '/images/perTercero.jpg',
    tag: 'Arte personalizado a tu espacio'
  }, {
    id: 'per04',
    title: 'Personalizado Cuarto',
    image: '/images/perCuarto.jpg',
    tag: 'Arte personalizado a tu espacio'
  }, {
    id: 'per05',
    title: 'Personalizado Quinto',
    image: '/images/perQuinto.jpg',
    tag: 'Arte personalizado a tu espacio'
  }
]

export default artwork;