import Container from '@mui/material/Container';
import { Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import ArtWorkList from './components/ArtworkList';
import AboutMe from './components/AboutMe';
import Contact from './components/Contact';

function App() {
  return (
    <>
      <Container maxWidth="lg">
        <Header />
        <Routes>
          <Route path="/" element={<ArtWorkList />} />
          <Route path="/acercaDeMi" element={<AboutMe />} />
          <Route path="/contacto" element={<Contact />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Footer />
      </Container>
    </>
  );
}

export default App;
