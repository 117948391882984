import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Container, FormControl, TextField, Button } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

function Contact () {
  const form = useRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_7x2ubbv', 'template_fcg6a6w', form.current, 'siujxCLVzkmAIqZIv')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

    setName("");
    setEmail("");
    setMessage("");
  };

  return (
    <Container maxWidth="md">
      <FormControl
        component="form"
        ref={form}
        onSubmit={sendEmail}
        noValidate
        autoComplete="off"
        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center'}}
      >        
        <TextField 
          label="Nombre" 
          variant="outlined" 
          name="user_name"
          value={name}
          onChange={e => setName(e.target.value)}
          fullWidth
          sx={{m: '1rem 0 0'}}
        />      
        <TextField 
          label="Correo electrónico" 
          variant="outlined"
          name="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          fullWidth
          sx={{m: '1rem 0 0'}}
        />
        <TextField 
          label="Mensaje" 
          name="message"
          multiline 
          rows={4} 
          value={message}
          onChange={e => setMessage(e.target.value)}
          fullWidth
          sx={{m: '1rem 0 0'}}
        />
        <Button 
          variant="contained" 
          type="submit" 
          value="Send" 
          disabled={!name || !email || !message} 
          endIcon={<SendIcon />}
          sx={{m: '1rem 0 0'}}
        >
          Enviar
        </Button>
      </FormControl>
    </Container>
  );
}

export default Contact;
