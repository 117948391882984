import { ImageList, ImageListItem } from '@mui/material';
import db from '../artwork';

function ArtWorkList() {
  const renderedArtwork = db.map(artwork => {
    return (
      <ImageListItem key={artwork.id}>
        <img 
          src={`${artwork.image}?w=248&fit=crop&auto=format`}
          srcSet={`${artwork.image}?w=248&fit=crop&auto=format&dpr=2 2x`}
          alt={artwork.title}
          loading="lazy"
        />
      </ImageListItem>
    );
  })

  return (
    <>
      <ImageList variant="masonry">
        { renderedArtwork }
      </ImageList>
    </>
  );
}

export default ArtWorkList;